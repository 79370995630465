/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerDepartment = params => axios({
    url: '/api/dealer/system/dealerDepartment/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addDealerDepartment = params => axios({
    url: '/api/dealer/system/dealerDepartment/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerDepartment = params => axios({
    url: '/api/dealer/system/dealerDepartment/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerDepartment = params => axios({
    url:'/api/dealer/system/dealerDepartment/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerDepartment = params => axios({
    url: '/api/dealer/system/dealerDepartment/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
